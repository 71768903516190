import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
    line-height: 1.6;
`;

const Logo = styled.h3`
    width: 120px;
    margin-bottom: 24px;
`;

const CompanyName = styled.strong`
    font-size: large;
`;

const CompanyDetails = styled.p`
    font-size: 12px;
    color: #666;
`;

const Separator = styled.span`
    margin-left: 15px;
    margin-right: 15px;
    color: #D9D9D9;
`;

const ContactNumberCover = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ContactNumber = styled.strong`
    font-size: large;
`;

const CustomerService = styled.p`
    margin-top: 1px;
    font-size: 12px;
    color: #666;
`;

const FooterLeft = () => {
    return (
        <FooterContainer>
            <Logo>
                <img src="/images/logo.png" alt=""/>
            </Logo>
            <div>
                <CompanyName>(주)티웨이브 역삼지점</CompanyName>
                <CompanyDetails>
                    사업자등록번호 : 291-85-01499
                    <Separator>|</Separator>
                    대표 : 서재준
                </CompanyDetails>
                <CompanyDetails>통신판매업신고 : 2021-서울강남-05837</CompanyDetails>
                <CompanyDetails>주소 : 서울시 강남구 삼성로85길 26, 7층(대치동, V&S)</CompanyDetails>
            </div>
            <ContactNumberCover>
                <ContactNumber>02-583-9442</ContactNumber>
            </ContactNumberCover>
            <CustomerService>전화문의 : 평일 10:00 ~ 17:00</CustomerService>
            <CustomerService>점심시간 : 12:00 ~ 13:00</CustomerService>
            <CustomerService>주말 & 공휴일 휴무</CustomerService>
        </FooterContainer>
    )
}

export default FooterLeft;
